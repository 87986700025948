import { SingleValue } from 'react-select'
import { SearchableSelect } from "../FormElements";
import clm from 'country-locale-map';

interface CountryCodeSelectProps {
  name: string,
  value: string,
  onChange: (contractType: any) => void
}

export function CountryCodeSelect({ value, name, onChange }: CountryCodeSelectProps) {

  const onHandleChange = (newValue: SingleValue<any>) => {
    const event = {
      target: {
        name: name,
        value: newValue ? newValue.value : ""
      }
    }
    onChange(event)
  }

  const label = {
    value: value,
    label: value ? value + " - " +  (clm.getCountryNameByAlpha3(value) || "Invalid country code") : "",
  }

  const countryCodeOptions = clm.getAllCountries().map((country) => {
    return {
      label: country.alpha3 + " - " + country.name,
      value: country.alpha3
    }
  });

  return (
    <SearchableSelect
      options={countryCodeOptions}
      value={label}
      onChange={onHandleChange}
      isClearable={true}
    />
  )
}
