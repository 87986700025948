import 'leaflet/dist/leaflet.css';
import { MapContainer } from 'react-leaflet'
import { TileLayer } from 'react-leaflet'
import { Marker } from 'react-leaflet'
import { Popup } from 'react-leaflet'
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import L from 'leaflet';
import { easApi } from '../api/edgeAdministrationShell/easApi'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router'
import Badge from '../components/Typography/Badge';
import { BadgeColor } from '../components/Typography/Badge';
import { easApiHooks } from '../api/edgeAdministrationShell/easApiHooks';


var greenIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

var blueIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

var yellowIcon = L.icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-yellow.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});



function LoadingIcon() {

  return (
    <div>
      <div id="wifi-loader">
        <svg className="circle-outer" viewBox="0 0 86 86">
          <circle className="back" cx="43" cy="43" r="40"></circle>
          <circle className="front" cx="43" cy="43" r="40"></circle>
          <circle className="new" cx="43" cy="43" r="40"></circle>
        </svg>
        <svg className="circle-middle" viewBox="0 0 60 60">
          <circle className="back" cx="30" cy="30" r="27"></circle>
          <circle className="front" cx="30" cy="30" r="27"></circle>
        </svg>
        <svg className="circle-inner" viewBox="0 0 34 34">
          <circle className="back" cx="17" cy="17" r="14"></circle>
          <circle className="front" cx="17" cy="17" r="14"></circle>
        </svg>
        <div className="text" data-text="loading"></div>
      </div>
    </div>
  )
}

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow
});
L.Marker.prototype.options.icon = DefaultIcon;

let initialPosition: any = [50.861483790914974, 10.332054985593048];

export default function Dashboard() {
  const navigate = useNavigate();
  const easEdgeDevices = easApiHooks.useGetDevices();

  if (easEdgeDevices.isLoading || easEdgeDevices.isIdle)
    return (
      <div className="flex justify-center items-center h-screen">
        <p></p>
        <LoadingIcon></LoadingIcon>
      </div>
    );

  if (easEdgeDevices.isError)
    return <div>Error: {easEdgeDevices.error.message}</div>;

  let markers: {
    lat: number;
    lon: number;
    name: string;
    status: string;
    customer?: string;
    description?: string;
  }[] = [];

  easEdgeDevices.data.forEach((device) => {
    if (device.geoLocation) {
      const latitude = Number(device.geoLocation.split(",")[0]);
      const longitude = Number(device.geoLocation.split(",")[1]);
      if (!isNaN(latitude) && !isNaN(longitude)) {
        markers.push({
          lat: latitude,
          lon: longitude,
          name: device.deviceName,
          status: device.deviceStatus,
          customer: device.customer,
          description: device.description,
        });
      }
    }
  });

  return (
    <>
      <MapContainer center={initialPosition} zoom={5} scrollWheelZoom={true}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {markers.map((item, index) => (
          <Marker
            key={index}
            icon={item.status === "Connected" ? greenIcon : blueIcon}
            position={[item.lat, item.lon]}
          >
            <Popup>
              <p onClick={() => navigate(`/devices/${item.name}`)}>
                Device-ID: {item.name}
                <br />
                Description: {item.description}
                <br />
                Customer: {item.customer}
              </p>
              <ConnectionStatusMini deviceId={item.name}></ConnectionStatusMini>
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </>
  );
}

interface ConnectionStatusData {
  iotEdgeRuntime: string;
  iotHub: string;
  sems: string;
  vpn: string;
}

export function ConnectionStatusMini({ deviceId }: { deviceId: string }) {
  const { isLoading, isError, isIdle, data, error } = useQuery<
    ConnectionStatusData,
    Error
  >({
    queryKey: ["getConnectionStatus", deviceId],
    queryFn: () => easApi.getConnectionStatus(deviceId),
  });

  if (isLoading || isIdle)
    return (
      <div className="p-2 rounded-sm ring-1 ring-inset animate-pulse ring-gray-200">
        <div className="h-[40px] bg-slate-300 rounded-sm" />
      </div>
    );

  if (isError) return <div>Error {error.message}</div>;

  return (
    <div>
      <Badge
        color={
          data.iotEdgeRuntime === "Connected"
            ? BadgeColor.Green
            : BadgeColor.Red
        }
      >
        Runtime
      </Badge>
      <Badge
        color={data.iotHub === "Connected" ? BadgeColor.Green : BadgeColor.Red}
      >
        IoTHub
      </Badge>
      <Badge
        color={data.sems === "Connected" ? BadgeColor.Green : BadgeColor.Red}
      >
        SmartEMS
      </Badge>
      <Badge
        color={data.vpn === "Connected" ? BadgeColor.Green : BadgeColor.Red}
      >
        VPN
      </Badge>
      <iframe
        src={`https://gea-live-dashboard.westeurope.cloudapp.azure.com/d-solo/8FRiDKlVz/alerts-test?orgId=1&var-device=${deviceId}&from=now-2h&to=now&panelId=12&refresh=10s`}
        title="graph5"
        width="100%"
        height="100"
      />
    </div>
  );
}
