import React, { useContext, useEffect } from 'react';
import { createContext, useState } from 'react';
import { easApiHooks } from '../../api/edgeAdministrationShell/easApiHooks';
import { useParams } from 'react-router-dom';
import { ConnectionParams } from './OPCUABrowser';
import { subscribeTopic } from '../../api/gesApi';
import { useVisibleItemsContext } from './TreeContext';

interface INodeIdValueContext {
  nodeIdValues?: NodeIdValues
}

interface NodeIdValues {
  [nodeId: string]: string
}

const NodeIdValueContext = createContext<INodeIdValueContext | undefined>(undefined);

function NodeIdValueContextProvider({ connectionParams, children }: {connectionParams: ConnectionParams, children: React.ReactNode}) {
  const { deviceId } = useParams();
  const { visibleTreeItems } = useVisibleItemsContext();

  const { data } = easApiHooks.useMonitorItems(deviceId, connectionParams, Array.from(visibleTreeItems), {
    refetchInterval: 60*1000
  });

  const [nodeIdValues, setNodeValue] = useState<NodeIdValues>();

  useEffect(() => {
      const controller = new AbortController();
      if (data && process.env.REACT_APP_API_GES_URI) {
        subscribeTopic(data.connectionId, controller, setNodeValue);
      }
    return () => {
      controller.abort();
    }
  }, [data]);

  const contextValue = {
    nodeIdValues
  }

  return (
    <NodeIdValueContext.Provider value={contextValue}>
      {children}
    </NodeIdValueContext.Provider>
  )
}

const useNodeIdValueContext = (): INodeIdValueContext => {
  const context = useContext(NodeIdValueContext);
  if (!context) {
    throw new Error('useNodeIdValueContext must be used within a NodeIdValueContextProvider');
  }
  return context;
};

export { NodeIdValueContextProvider, useNodeIdValueContext }