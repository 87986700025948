import axios, { InternalAxiosRequestConfig } from "axios";
import { msalInstance } from "../..";
import { spApiRequest } from "../../config/authConfig";
import { ContractGet, ContractUpdate } from "./interfaces/contracts";
import { OrderGet } from "./interfaces/orders";
import { EdgeDeviceGet } from "./interfaces/edge_devices";
import { MachineCreate, MachineGet, MachineUpdate } from "./interfaces/machines";

export const spApiInstance = axios.create({
  baseURL: `${process.env.REACT_APP_STARTER_PACKAGES_URI}`,
});

spApiInstance.interceptors.request.use(
  async function (config: InternalAxiosRequestConfig) {
    try {
      const tokenResponse = await msalInstance.acquireTokenSilent(spApiRequest);
      config.headers["Authorization"] = `Bearer ${tokenResponse.accessToken}`;
    } catch (error) {
      msalInstance.acquireTokenRedirect(spApiRequest);
    }
    return config;
  }
);

// Order
const getOrder = async (orderId?: string, posId?: string) => {
  const { data } = await spApiInstance.get<OrderGet>(`/v1/order/${orderId}/${posId}`)
  return data;
}

const getOrders = async (filter?: string) => {
  const queryParams = { $filter: filter };
  const { data } = await spApiInstance.get<OrderGet[]>(`/v1/order`,  { params: queryParams })
  return data;
}

const createOrder = async (payload: any) => {
  const { data } = await spApiInstance.post<OrderGet>(`/v1/order`, payload)
  return data;
}

// Contract
const getContract = async (orderId?: string, posId?: string) => {
  const { data } = await spApiInstance.get<ContractGet>(`/v1/contract/${orderId}/${posId}/contract`);
  return data;
}

const getContracts = async (filter?: string) => {
  const queryParams = { $filter: filter };
  const { data } = await spApiInstance.get<ContractGet[]>(`/v1/contract`, { params: queryParams });
  return data;
}

const createContract = async (payload: ContractUpdate) => {
  const { data } = await spApiInstance.post<ContractGet>('/v1/contract', payload);
  return data;
}

const updateContract = async (orderId: string | undefined, posId: string | undefined, payload: ContractUpdate) => {
  const { data } = await spApiInstance.post<ContractGet>(`/v1/contract/${orderId}/${posId}/update`, payload);
  return data;
}

const postAddMachineToOrder = async (order_id?: string, pos_id?: string, machineNumber?: string) => {
  const { data } = await spApiInstance.post<OrderGet>(`/v1/order/${order_id}/${pos_id}/machine/${machineNumber}`);
  return data;
}

const deleteRemoveMachineFromOrder = async (order_id?: string, pos_id?: string, machineNumber?: string) => {
  const { data } = await spApiInstance.delete(`/v1/order/${order_id}/${pos_id}/machine/${machineNumber}`);
  return data;
}

// Edge device
const getEdgeDevices = async (filter?: string) => {
  const queryParams = { $filter: filter };
  const { data } = await spApiInstance.get<EdgeDeviceGet[]>(`/v1/edge_device`, { params: queryParams })
  return data;
}

const getEdgeDevice = async (edgeDeviceId?: string) => {
  const { data } = await spApiInstance.get<EdgeDeviceGet>(`/v1/edge_device/${edgeDeviceId}`);
  return data;
}

const updateEdgeDevice = async (edgeDeviceId: string | undefined, payload: any) => {
  const { data } = await spApiInstance.post<EdgeDeviceGet>(`/v1/edge_device/${edgeDeviceId}`, payload);
  return data;
}

const getEdgeDeviceStatusHistory = async (edgeDeviceId: string, limit: number | undefined) => {
  const { data } = await spApiInstance.get<EdgeDeviceGet>(`/v1/edge/${edgeDeviceId}`, { params: { limit: limit } });
  return data;
}

const postAddMachineToContract = async (order_id?: string, pos_id?: string, machineNumber?: string) => {
  const { data } = await spApiInstance.post<ContractGet>(`/v1/contract/${order_id}/${pos_id}/machine/${machineNumber}`);
  return data;
}

const deleteRemoveMachineFromContract = async (order_id?: string, pos_id?: string, machineNumber?: string) => {
  const { data } = await spApiInstance.delete(`/v1/contract/${order_id}/${pos_id}/machine/${machineNumber}`);
  return data;
}

// EdgeMachine
const getEdgeMachines = async (filter?: string) => {
  const queryParams = { $filter: filter };
  const { data } = await spApiInstance.get<MachineGet[]>(`/v1/edge_machine`, { params: queryParams });
  return data
}

const getEdgeMachine = async (machine_number?: string) => {
  const { data } = await spApiInstance.get<MachineGet>(`/v1/edge_machine/${machine_number}`);
  return data
}

const createEdgeMachine = async (payload: MachineCreate) => {
  const { data } = await spApiInstance.post<MachineGet>(`/v1/edge_machine`, payload);
  return data;
}

const updateEdgeMachine = async (machine_number: string | undefined, payload: MachineUpdate) => {
  const { data } = await spApiInstance.post(`/v1/edge_machine/${machine_number}`, payload);
  return data;
}

const deleteEdgeMachine = async (machine_number: string | undefined) => {
  const { data } = await spApiInstance.delete(`/v1/edge_machine/${machine_number}`);
  return data;
}

// Misc
const getCountryCodes = async () => {
  const { data } = await spApiInstance.get<string[]>(`/v1/country/country_codes`);
  return data;
}

const getCurrencyCodes = async () => {
  const { data } = await spApiInstance.get<string[]>(`/v1/currency/currency_codes`);
  return data;
}

export const spApi = {
  getOrder,
  getOrders,
  createOrder,
  postAddMachineToOrder,
  deleteRemoveMachineFromOrder,

  getContract,
  getContracts,
  createContract,
  updateContract,
  postAddMachineToContract,
  deleteRemoveMachineFromContract,

  getEdgeDevices,
  getEdgeDevice,
  updateEdgeDevice,
  getEdgeDeviceStatusHistory,

  getEdgeMachines,
  getEdgeMachine,
  createEdgeMachine,
  updateEdgeMachine,
  deleteEdgeMachine,

  getCountryCodes,
  getCurrencyCodes
}
