import { Configuration, RedirectRequest } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { jwtDecode, JwtPayload } from "jwt-decode";
import { useQuery } from "react-query";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_ENTRA_ID_CLIENT_ID || "",
    authority: process.env.REACT_APP_ENTRA_ID_AUTHORITY || "",
    redirectUri: process.env.REACT_APP_ENTRA_ID_REDIRECT_URI || "",
  },
  system: {
    allowNativeBroker: false // Disables WAM Broker
  }
};

export const getRolesFromToken = (token: string) => {
  const decodedToken = jwtDecode(token);
  return decodedToken || [];
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const easApiLoginRequest: RedirectRequest = {
  scopes: process.env.REACT_APP_API_SCOPES?.split(",") || [""]
};

export const spApiRequest: RedirectRequest = {
  scopes: process.env.REACT_APP_STARTER_PACKAGES_SCOPES?.split(",") || [""]
};

export const graphApiLoginRequest: RedirectRequest = {
  scopes: ["User.Read", "User.ReadBasic.All"]
}

interface RoleJwtPayload extends JwtPayload {
  roles?: string[]
}

export const useGetJwt = (loginRequest: RedirectRequest) => {
  const { instance } = useMsal();

  const query = useQuery({
    queryKey: ['authToken', loginRequest],
    queryFn:  async () => {
      const authResult = await instance.acquireTokenSilent(loginRequest)
      return jwtDecode<RoleJwtPayload>(authResult.accessToken)
    },
    enabled: !!instance,
  });

  return query;
};