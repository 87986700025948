import { useParams } from "react-router-dom";
import {
  Heading,
  HeadingButton,
  HeadingColor,
} from "../../../components/Typography/Heading";
import {
  InformationCircleIcon,
  PencilSquareIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import DictionaryList from "../../../components/Table/DictionaryList";
import { useState } from "react";
import { Input } from "../../../components/Input/FormElements";
import clm from "country-locale-map";
import {
  DeviceData,
  easApiHooks,
} from "../../../api/edgeAdministrationShell/easApiHooks";
import { toast } from "react-toastify";
import { CountryCodeSelect } from "../../../components/Input/Select/CountryCodeSelect";

export default function BasicInformation() {
  const { deviceId } = useParams();
  const [isEditing, setIsEditing] = useState<boolean>(false); // Same component as mutation, same component as save, cancel and edit button
  const easDevice = easApiHooks.useGetDeviceTags(deviceId);
  const country = formatCountry(easDevice.data?.countryCode);

  const tableData = {
    "Country-Code": country,
    City: easDevice.data?.city,
    "Geo-Location": easDevice.data?.geoLocation,
    Customer: easDevice.data?.customer,
    "IoT-Hub Description": easDevice.data?.description,
  };

  if (easDevice.data && isEditing) {
    return (
      <BasicInformationForm
        easDevice={easDevice.data}
        stopEditing={() => setIsEditing(false)}
      />
    );
  }

  return (
    <div>
      <Heading processing={easDevice.isFetching} color={HeadingColor.Gray}>
        <InformationCircleIcon className="w-7 h-7 mr-1" />
        Basic Information
        {easDevice.isSuccess && (
          <HeadingButton onClick={() => setIsEditing(!isEditing)}>
            <PencilSquareIcon className="w-6 h-6 ml-5" />
            Edit
          </HeadingButton>
        )}
      </Heading>
      <DictionaryList dictionary={tableData} processing={!easDevice.data} />
    </div>
  );
}

interface BasicInformationFormProps {
  easDevice: DeviceData;
  stopEditing: () => void;
}

function BasicInformationForm({
  easDevice,
  stopEditing,
}: BasicInformationFormProps) {
  const { deviceId } = useParams();
  if (!deviceId) throw new Error("No deviceId in params.");
  const [easDeviceFormData, setEasDeviceFormData] = useState({
    countryCode: easDevice.countryCode,
    city: easDevice.city,
    geoLocation: easDevice.geoLocation,
    customer: easDevice.customer,
    description: easDevice.description,
  });
  const postDeviceTagsMutation = easApiHooks.usePostDeviceTags(deviceId);

  const onSubmit = () => {
    const payload = {
      deviceTags: {
        countryCode: easDeviceFormData.countryCode || "",
        city: easDeviceFormData.city || "",
        geoLocation: easDeviceFormData.geoLocation || "",
        customer: easDeviceFormData.customer || "",
        description: easDeviceFormData.description || "",
      },
    };
    postDeviceTagsMutation.mutate(payload, {
      onSuccess: stopEditing,
      onError: () => {
        toast.error(`ERROR: Could not update edge device information.`);
      },
    });
  };

  const handleChange = (ev: any) => {
    const { name, value } = ev.target;
    setEasDeviceFormData((formData) => ({
      ...formData,
      [name]: value,
    }));
  };

  const tableData = {
    "Country-Code": (
      <CountryCodeSelect
        name="countryCode"
        value={easDeviceFormData.countryCode || ""}
        onChange={handleChange}
      />
    ),
    City: (
      <Input
        type="text"
        name="city"
        value={easDeviceFormData.city || ""}
        onChange={handleChange}
        className="p-1 w-full"
      />
    ),
    "Geo-Location": (
      <Input
        type="text"
        name="geoLocation"
        value={easDeviceFormData.geoLocation || ""}
        onChange={handleChange}
        className="p-1 w-full"
      />
    ),
    Customer: (
      <Input
        type="text"
        name="customer"
        value={easDeviceFormData.customer || ""}
        onChange={handleChange}
        className="p-1 w-full"
      />
    ),
    "IoT-Hub Description": (
      <Input
        type="text"
        name="description"
        value={easDeviceFormData.description || ""}
        onChange={handleChange}
        className="p-1 inline-block w-full"
      />
    ),
  };

  return (
    <div>
      <Heading color={HeadingColor.Gray}>
        <InformationCircleIcon className="w-7 h-7 mr-1" />
        Basic Information
        <HeadingButton onClick={onSubmit}>
          <PencilSquareIcon className="w-6 h-6 ml-5" />
          Save
        </HeadingButton>
        <HeadingButton onClick={() => stopEditing()}>
          <XMarkIcon className="w-6 h-6 ml-5" />
          Cancel
        </HeadingButton>
      </Heading>
      <DictionaryList dictionary={tableData} />
    </div>
  );
}

function formatCountry(countryCode?: string) {
  if (!countryCode) return "";
  const countryName = clm.getCountryNameByAlpha3(countryCode);
  if (countryName) return `${countryCode} - ${countryName}`;
  return countryCode;
}
