import { QueryClientProvider } from "react-query";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import DeviceList from "./features/Devices/DeviceList";
import MainLayout from "./layouts/MainLayout";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { queryClient } from './config/queryConfig';
import Dashboard from "./pages/Dashboard";

import * as Sentry from "@sentry/react";
import SidebarLayout from "./layouts/SidebarLayout";
import DeviceDetail from "./features/Devices/DeviceDetail";
import ErrorFallback from "./pages/ErrorFallback";
import MachineList from "./features/EdgeMachines/MachineList";
import MachineDetail from "./features/EdgeMachines/MachineDetail";
import PageNotFound from "./pages/PageNotFound";
import ContractList from "./features/Contracts/ContractList";
import OrderList from "./features/Orders/OrderList";
import MachineCreate from "./features/EdgeMachines/EdgeMachineCreate";
import ContractCreate from "./features/Contracts/ContractCreate";
import Version from "./pages/Version";
import ContractPage from "./features/Contracts/ContractPage";
import OrderPage from "./features/Orders/OrderPage";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <SentryRoutes>
          <Route path="/" element={<MainLayout />}>
            <Route index element={<Dashboard />} errorElement={<ErrorFallback />} />
            <Route path="devices" element={<Outlet />}>
              <Route index element={<div className="h-full overflow-y-auto"><DeviceList /></div>} />
              <Route path=":deviceId/*" element={<SidebarLayout sidebar={<DeviceList />}/>}>
                <Route path="*" element={<DeviceDetail />} />
              </Route>
            </Route>

            {process.env.REACT_APP_STARTER_PACKAGES_URI && <>
              <Route path="machines" element={<div className="h-full overflow-y-auto"><MachineList /></div>} />
              <Route path="machines/*" element={<SidebarLayout sidebar={<MachineList />}/>}>
                <Route path="create" element={<MachineCreate />} />
                <Route path=":machineNumber" element={<MachineDetail />} />
              </Route>

              <Route path="contracts" element={<div className="h-full overflow-y-auto"><ContractList /></div>} />
              <Route path="contracts/*" element={<SidebarLayout sidebar={<ContractList />}/>}>
                <Route path="create" element={<ContractCreate />} />
                <Route path=":orderId/:posId/*" element={<ContractPage />} />
              </Route>
            
              <Route path="orders" element={<Outlet />}>
                <Route index element={<div className="h-full overflow-y-auto"><OrderList /></div>} />
                <Route path=":orderId/:posId/*" element={<SidebarLayout sidebar={<OrderList />}/>}>
                  <Route path="*" element={<OrderPage />} />
                </Route>
              </Route>
            </>}
            <Route path="version" element={<Version />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </SentryRoutes>
      </BrowserRouter>
      <ToastContainer position="bottom-right" />
    </QueryClientProvider>
  )
}